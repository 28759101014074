<template>
  <div>
    <el-container>
      <!-- el-aside -->
      <el-aside width="220px">
        <el-select placeholder="请选择"></el-select>
        <el-tree :data="data" node-key="id" :default-expanded-keys="[1]">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span>
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="onAddTree"
                    >新建同级分类</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="onEditTree" divided
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="onDeleteTree"
                    >删除</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="onDisableTree"
                    >禁用</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </span>
        </el-tree> </el-aside
      ><!-- end el-aside -->
      <el-main>
        <!-- top -->
        <div class="top">
          <div class="left">
            <el-select v-model="value" placeholder="请选择" size="mini">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-date-picker
              v-model="value3"
              type="daterange"
              align="left"
              size="mini"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-select v-model="value2" placeholder="请选择" size="mini">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="right">
            <el-button @click="onDialog" size="mini" type="primary"
              >保存为常用</el-button
            >
            <el-button
              @click="onDialog"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              >新增收支</el-button
            >
          </div>
        </div>
        <!-- end top -->
        <!-- center -->
        <div class="center">
          <div>
            <div class="image">
              <img src="../../../../assets/img/left.jpg" />
            </div>
            <div class="money">
              <span>
                <i>收入</i>
                <i>(共0笔)</i>
              </span>
              <span>0元</span>
            </div>
          </div>
          <div>
            <div class="image">
              <img src="../../../../assets/img/rigth.jpg" />
            </div>
            <div class="money">
              <span>
                <i>支出</i>
                <i>(共0笔)</i>
              </span>
              <span>0元</span>
            </div>
          </div>
        </div>
        <!-- end center -->
        <!-- table -->
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="calc(100vh - 420px)"
        >
          <el-table-column type="selection" width="48"></el-table-column>
          <el-table-column
            prop="date"
            label="开支日期"
            width="300"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="收支类型"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="province"
            label="收支类目"
            width="120"
          ></el-table-column>
          <el-table-column prop="city" label="支付/收款方式" width="120">
          </el-table-column>
          <el-table-column prop="city" label="金额" width="200">
          </el-table-column>
          <el-table-column prop="city" label="相关人员" width="200">
          </el-table-column>
          <el-table-column
            prop="city"
            label="备注说明"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="city"
            label="录入人/录入时间"
            width="120"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="220">
            <template slot-scope="scope">
              <el-button @click="onEditList" type="primary" size="mini"
                >修改</el-button
              >
              <el-button type="danger" size="mini">删除</el-button>
            </template>
          </el-table-column> </el-table
        ><!-- end table -->
        <!-- pagination -->
        <el-pagination
          background
          :page-sizes="[100, 200, 300, 400]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="400"
        >
        </el-pagination
        ><!--pagination-->
      </el-main>
    </el-container>
    <EditDialog ref="editDialog"></EditDialog>
    <AddDialog ref="addDialog"></AddDialog>
    <!-- el-dialog -->
    <el-dialog
      :title="dialogData.title"
      :visible.sync="dialogData.isDialog"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form>
        <el-form-item :label="dialogData.label">
          <el-input v-model="input" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item class="left">
          <el-button>取消</el-button>
          <el-button type="primary">确认</el-button>
        </el-form-item>
      </el-form> </el-dialog
    ><!-- end el-dialog -->
  </div>
</template>

<script>
import EditDialog from "./components/edit-dialog.vue";
import AddDialog from "./components/add-dialog.vue";
export default {
  components: { EditDialog, AddDialog },
  data() {
    return {
      data: [
        {
          id: 1,
          label: "全部收支",
          children: [
            {
              id: 2,
              label: "全部支出",
              children: [
                {
                  id: 10,
                  label: "分类：退款",
                  children: [
                    {
                      id: 13,
                      label: "项目：会员卡",
                    },
                  ],
                },
                {
                  id: 11,
                  label: "分类：店铺支出",
                },
              ],
            },
            {
              id: 3,
              label: "全部收入",
              children: [
                {
                  id: 10,
                  label: "洗剪吹1-1",
                },
              ],
            },
          ],
        },
      ],
      value: "开支日期",
      value2: "全部员工",
      options: [
        {
          label: "开支日期",
          value: "1",
        },
        {
          label: "录入日期",
          value: "2",
        },
      ],
      options2: [
        {
          label: "全部员工",
          value: "1",
        },
        {
          label: "开支日期",
          value: "2",
        },
        {
          label: "录入日期",
          value: "2",
        },
      ],
      value3: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableData: [
        {
          date: "2016-05-02",
          name: "A类",
          province: "100",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-04",
          name: "B类",
          province: "200",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-02",
          name: "A类",
          province: "100",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-04",
          name: "B类",
          province: "200",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-02",
          name: "A类",
          province: "100",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-04",
          name: "B类",
          province: "200",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-02",
          name: "A类",
          province: "100",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-04",
          name: "B类",
          province: "200",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-02",
          name: "A类",
          province: "100",
          city: "正常",
          zip: 200333,
        },
        {
          date: "2016-05-04",
          name: "B类",
          province: "200",
          city: "正常",
          zip: 200333,
        },
      ],
      dialogData: {
        // 对话框数据
        title: "",
        isDialog: false,
        label: "",
      },
    };
  },
  methods: {
    onDialog() {
      this.$refs.addDialog.dialog = true;
    },
    onEditList() {
      this.$refs.editDialog.dialog = true;
    },
    onAddTree() {
      this.dialogData.title = "新建同级分类";
      this.dialogData.label = "分类名称";
      this.dialogData.isDialog = true;
    },
    onEditTree() {
      this.dialogData.title = "修改分类";
      this.dialogData.label = "分类名称";
      this.dialogData.isDialog = true;
    },
    onDeleteTree() {
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onDisableTree() {
      this.$confirm("此操作将禁用该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "禁用成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消禁用",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  margin-top: 20px;
  .el-aside {
    height: calc(100vh - 160px);
    border-right: 1px solid #d3dce6;
    .el-select {
      width: 219px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 0;
      .el-dropdown-link {
        display: inline-block;
        height: 36px;
        width: 36px;
        text-align: center;
        line-height: 36px;
        color: #bfcbd9;
      }
      .el-dropdown-link:hover {
        background-color: #d3dce6;
        color: #8492a6;
      }
    }
  }
  .el-main {
    position: relative;
    padding: 0 0 0 20px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
        display: flex;
        align-items: center;
        .el-date-editor {
          margin: 0 10px;
        }
      }
      .right {
        display: flex;
        align-items: center;
      }
      .el-input {
        width: 300px !important;
      }
      .el-button {
        margin-left: 10px;
      }
    }
    .center {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      > div {
        display: flex;
        justify-content: space-between;
        flex: 1;
        height: 140px;
        border-radius: 5px;
        overflow: hidden;
        .image {
          height: 140px;
          width: 140px;
          background-color: pink;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .money {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          span:first-child {
            margin-bottom: 10px;
          }
          span:last-child {
            font-size: 20px;
          }
          i {
            font-style: normal;
          }
          i:first-child {
            font-size: 16px;
          }
          i:last-child {
            color: #99a9bf;
            margin-left: 10px;
          }
        }
      }
      > div:first-child {
        margin-right: 10px;
      }
      > div:last-child {
        margin-left: 10px;
      }
    }
    .el-table {
      .image-text {
        display: flex;
        .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 56px;
          margin-left: 10px;
          width: 200px;
          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .size {
            font-size: 10px;
          }
        }
      }
      .el-image {
        width: 56px;
        height: 56px;
        border-radius: 5px;
        vertical-align: middle;
      }
    }

    .el-pagination {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.el-dialog {
  .left {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}
</style>
