<template>
  <div>
    <!--  -->
    <el-dialog
      title="添加收入"
      :visible.sync="dialog"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        label-position="left"
        class="demo-ruleForm"
      >
        <div class="auto">
          <div class="dialog-center">
            <div class="tabs-chile">
              <div class="title">收支信息：</div>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="收支类型" prop="date1">
                      <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="收支类目" prop="date1">
                      <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="收支金额" prop="date2">
                      <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="关联员工" prop="date1">
                      <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <div class="title">支付信息：</div>
              <el-form-item label-width="auto" required>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="支付方式" prop="date2">
                      <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="开支时间" prop="date1">
                      <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <div class="title">备注说明：</div>
              <el-form-item label="备注">
                <el-input type="textarea" v-model="ruleForm.desc"></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="dialog-bottom">
          <el-form-item>
            <el-button type="info">取消</el-button>
            <el-button type="primary" @click="onSubmit">确认</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      ruleForm: {
        number: "",
        name: "",
        region: "",
        type: [],
        desc: "",
      },
      rules: {
        number: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    onTabs(index) {
      this.isAction = index;
    },
    onSubmit() {},
  },
};
</script>

<style lang="less" scoped>
.dialog-center {
  margin-top: 20px;
  box-sizing: border-box;
  .tabs-chile {
    padding: 0 20px;
  }
  .title {
    margin: 20px 0;
    color: #000000;
    font-size: 16px;
  }
}

.dialog-bottom {
  margin-top: 20px;
  .el-form-item {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}
</style>
